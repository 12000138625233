'use strict'

import { useEffect, useState, useContext, useRef } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
// import Stack from '@mui/material/Stack'
// import Switch from '@mui/material/Switch'
import Select from '@mui/material/Select'
import Dialog from '@mui/material/Dialog'
// import AppBar from '@mui/material/AppBar'
// import Toolbar from '@mui/material/Toolbar'
// import IconButton from '@mui/material/IconButton'
// import CloseIcon from '@mui/icons-material/Close'
// import Autocomplete from '@mui/material/Autocomplete'
// import Icon from '@mui/material/Icon'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Link as MuiLink
} from '@mui/material'
// import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
// import FormHelperText from '@mui/material/FormHelperText'
// import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
// import MDAlert from 'components/MDAlert'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
// zerouno
import registryService from 'services/registry.service'
import mainService from 'services/invoice.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils'
import ZuDataTable from 'components/ZeroUno/ZuDataTable'
import RegistryNav from './RegistryNav'

export default function InvoiceForm() {
  const { idObj } = useParams()
  const { idRegistry } = useParams()
  const [obj, setObj] = useState({})
  const [registry, setRegistry] = useState({})
  const [rows, setRows] = useState([])
  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue
  } = useForm()
  const navigate = useNavigate()
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [rowInvoice, setRowInvoice] = useState({})
  const [openConfirm, setOpenConfirm] = useState(false)
  const [rowInvoiceId, setRowInvoiceId] = useState(-1)

  useEffect(() => {
    fetchObject()
    fetchRegistry(idRegistry)
  }, [])

  useEffect(() => {
    reset({ ...obj })
  }, [obj])

  const fetchObject = () => {
    // if (idObj === 'new') {
    //   setObj({
    //     date: DateTime.now(),
    //     registryId: idRegistry
    //   })
    // } else {
    mainService.findById(idObj).then(
      response => {
        setObj(response.data)
        setRegistry(response.data.registry)
        setRows(response.data.rows)
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
    // }
  }

  const fetchRegistry = registryId => {
    registryService.findById(registryId).then(
      response => {
        setRegistry(response.data)
      },
      error =>
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
    )
  }

  const columns = [
    {
      header: 'Codice',
      accessorKey: 'code',
      size: 10
    },
    {
      header: 'Descrizione',
      accessorKey: 'description',
      size: 10
    },
    {
      header: 'Qta',
      accessorKey: 'quantity',
      size: 10
    },
    {
      header: 'Prezzo',
      size: 10,
      accessorFn: row =>
        '€ ' + row.taxable.toFixed(2).toLocaleString('it').replace('.', ',')
    },
    {
      header: 'Iva',
      size: 10,
      accessorFn: row => row.vat + '%'
    },
    {
      header: 'Totale',
      size: 10,
      accessorFn: row =>
        '€ ' +
        (row.taxable * row.quantity * (1 + row.vat / 100))
          .toFixed(2)
          .toLocaleString('it')
          .replace('.', ',')
    },
    {
      header: 'azioni',
      enableSorting: false,
      enableColumnFilter: false,
      size: 10,
      width: 100,
      Cell: ({ row }) => (
        <>
          <MDButton
            color='info'
            size='small'
            iconOnly
            sx={{ mr: 1 }}
            onClick={() => {
              setRowInvoiceId(row.index)
              setRowInvoice(row.original)
              setCreateModalOpen(true)
            }}
          >
            <Icon>edit</Icon>
          </MDButton>
          <MDButton
            iconOnly
            color='error'
            size='small'
            onClick={() => {
              setRowInvoiceId(row.index)
              setOpenConfirm(true)
            }}
          >
            <Icon>delete</Icon>
          </MDButton>
        </>
      )
    }
  ]

  const handleSavedRow = values => {
    values.taxable = parseFloat(values.taxable)
    values.quantity = parseFloat(values.quantity)
    if (values.id) {
      setRows(
        rows.map((r, i) => {
          if (rowInvoiceId == i) {
            return values
          } else {
            return r
          }
        })
      )
      setRowInvoiceId(-1)
    } else {
      setRows([...rows, values])
    }
  }

  const deleteRow = () => {
    const _rows = [...rows]
    if (rowInvoiceId > -1) {
      _rows.splice(rowInvoiceId, 1)
      setRows(_rows)
    }
    setOpenConfirm(false)
    setRowInvoiceId(-1)
  }

  const onSubmitInvoice = data => {
    data.rows = rows
    data.rows.forEach(row => {
      row.amount = String(row.amount).replace('.', '').replace(',', '.')
    })
    // if (idObj === 'new') {
    //   addObj(data)
    // } else {
    data.id = obj.id
    updateObj(data, obj.id)
    // }
  }

  // Add obj
  // const addObj = (data) => {
  //   mainService.create(data).then(
  //     (response) => {
  //       setShowSnackbar({
  //         sh: true,
  //         msg: 'operazione effettuata con successo',
  //         color: 'success'
  //       })
  //       navigate(`/registries/${idRegistry}/invoices`)
  //     },
  //     (error) => {
  //       setShowSnackbar({
  //         sh: true,
  //         msg: error.response
  //           ? error.response.data.message
  //           : 'errore di ricezione dati',
  //         color: 'error'
  //       })
  //     }
  //   )
  // }

  const updateObj = (data, id) => {
    console.log('data: ', data)
    mainService.update(id, data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate(
          idRegistry ? `/registries/${idRegistry}/invoices` : '/invoices'
        )
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={10}>
            <form onSubmit={handleSubmit(onSubmitInvoice)}>
              <Card>
                <ZUTitleForm bgColor='warning' title='Fattura' icon='settings' />
                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDTypography variant='h5' fontWeight='medium'>
                        Cliente: {registry.businessName}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDTypography variant='h6' fontWeight='medium'>
                        Dati fattura
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='Numero'
                        error={!!errors.number}
                        {...register('number', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='date'
                        required
                        label='data'
                        error={!!errors.dateInvoice}
                        {...register('dateInvoice', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <ZuDataTable
                        table={{ columns: columns, rows: rows }}
                        renderTopToolbarCustomActions={() => (
                          <MDButton
                            color='secondary'
                            onClick={() => {
                              setRowInvoice({
                                vat: 22.0,
                                activationDate: new Date(),
                                expireDate: null
                              })
                              setCreateModalOpen(true)
                            }}
                            variant='contained'
                          >
                            nuova
                          </MDButton>
                        )}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient'
                    color='secondary'
                    component={Link}
                    to='/invoices'
                  >
                    Indietro (tutte le fatture)
                  </MDButton>
                  <MDButton
                    variant='gradient'
                    color='secondary'
                    component={Link}
                    to={`/registries/${idRegistry}/invoices`}
                    sx={{ ml: 1 }}
                  >
                    Indietro (fatture cliente)
                  </MDButton>
                  <MDButton
                    type='submit'
                    variant='gradient'
                    color='success'
                    sx={{ ml: 1 }}
                  >
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <RegistryNav id={idRegistry} />
          </Grid>
        </Grid>
      </MDBox>
      {createModalOpen && (
        <InvoiceRowModal
          open
          onClose={() => setCreateModalOpen(false)}
          OnSaved={handleSavedRow}
          rowInvoice={rowInvoice}
        />
      )}
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione riga?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteRow} autoFocus>
            Conferma
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}

export const InvoiceRowModal = ({ open, onClose, OnSaved, rowInvoice }) => {
  const [rowObj, setRowObj] = useState({
    ...rowInvoice,
    taxable: utils.formatFloat(rowInvoice.taxable, 2),
    quantity: utils.formatFloat(rowInvoice.quantity, 2)
  })

  const {
    register,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
    handleSubmit
  } = useForm()

  useEffect(() => { }, [])

  useEffect(() => {
    reset({ ...rowObj })
  }, [rowObj])

  const onSubmitDialog = data => {
    data.quantity = String(data.quantity).replace(',', '.')
    data.taxable = String(data.taxable).replace(',', '.')
    OnSaved(data)
    reset()
    onClose()
  }

  return (
    <Dialog open={open}>
      <form onSubmit={handleSubmit(onSubmitDialog)}>
        <DialogTitle textAlign='center'>Crea riga</DialogTitle>
        <DialogContent>
          <MDBox pt={3} pb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <MDInput
                  fullWidth
                  type='text'
                  label='Codice'
                  error={!!errors.code}
                  {...register('code', { required: false })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <MDInput
                  fullWidth
                  type='text'
                  label='Descrizione'
                  error={!!errors.description}
                  {...register('description', { required: false })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <MDInput
                  fullWidth
                  type='text'
                  defaultValue={1}
                  label='Quantità'
                  error={!!errors.quantity}
                  {...register('quantity', {
                    required: false,
                    pattern: utils.floatPattern
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl fullWidth required error={!!errors.vat}>
                  <InputLabel>iva</InputLabel>
                  <Select
                    label='iva'
                    defaultValue={rowObj.vat || undefined}
                    {...register('vat', { required: true })}
                  >
                    <MenuItem key={0} value={0.0}>
                      0 %
                    </MenuItem>
                    <MenuItem key={4} value={4.0}>
                      4 %
                    </MenuItem>
                    <MenuItem key={10} value={10.0}>
                      10 %
                    </MenuItem>
                    <MenuItem key={22} value={22.0}>
                      22 %
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <MDInput
                  fullWidth
                  type='text'
                  required
                  label='prezzo imponibile'
                  error={!!errors.taxable}
                  {...register('taxable', {
                    required: true,
                    pattern: utils.floatPattern
                  })}
                />
              </Grid>
            </Grid>
          </MDBox>
        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
          <MDButton onClick={onClose}>Annulla</MDButton>
          <MDButton type='submit' color='secondary' variant='contained'>
            Salva
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
