import React, { useEffect, useContext } from 'react'
// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'
import MDDropdown from 'components/MDDropdown'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'

// zerouno
import registryService from 'services/registry.service'
import { Link } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import RabbitContext from 'context/rabbit.js'
import ZuDataTable from 'components/ZeroUno/ZuDataTable'

export default function RegistryTable() {
  const [objId, setObjId] = React.useState(0)
  const [objects, setObjects] = React.useState([])
  const [showSnackbar, setShowSnackbar] = React.useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const [openMenuActions, setOpenMenuActions] = React.useState(false)

  useEffect(() => {
    fetchObjects()
  }, [])

  const fetchObjects = () => {
    registryService.findAll().then(
      (response) => {
        setObjects(response.data)
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  // Delete
  const deleteObject = () => {
    setOpenConfirm(false)
    registryService.remove(objId).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        fetchObjects()
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const columns = [
    {
      header: 'Ragione Sociale / Cognome Nome',
      accessorKey: 'businessName',
      accessorFn: (r) =>
        r.businessName ? r.businessName : r.surname + ' ' + r.name
    },
    {
      header: 'email',
      accessorKey: 'email'
    },
    {
      header: 'pec',
      accessorKey: 'pec'
    },
    {
      header: 'azioni',
      enableSorting: false,
      enableColumnFilter: false,
      accessorKey: 'id',
      size: 100,
      Cell: ({ cell }) => (
        <Grid container spacing={1}>
          {false && (
            <Grid item>
              <MDButton
                color='primary'
                size='small'
                component={Link}
                to={'/registries/' + cell.getValue() + '/orders'}
              >
                ordini
              </MDButton>
            </Grid>
          )}
          {false && (
            <Grid item>
              <MDButton
                color='primary'
                size='small'
                component={Link}
                to={'/registries/' + cell.getValue() + '/payments'}
              >
                pagamenti
              </MDButton>
            </Grid>
          )}
          <Grid item>
            <MDButton
              color='info'
              size='small'
              iconOnly
              component={Link}
              to={'/registries/' + cell.getValue()}
            >
              <Icon>edit</Icon>
            </MDButton>
          </Grid>
          <Grid item>
            <MDButton
              iconOnly
              color='error'
              size='small'
              onClick={() => {
                setObjId(cell.getValue())
                setOpenConfirm(true)
              }}
            >
              <Icon>delete</Icon>
            </MDButton>
          </Grid>
        </Grid>
      )
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <ZUTitleForm
            bgColor='warning'
            title='Clienti / Fornitori'
            icon='settings'
          />

          <MDBox p={3} lineHeight={1}>
            <MDButton color='info' component={Link} to='/registries/new' m={1}>
              nuovo
            </MDButton>
          </MDBox>
          <ZuDataTable table={{ columns: columns, rows: objects }} />
        </Card>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>
            Conferma
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
