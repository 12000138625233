'use strict'
import { useState } from 'react'
// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Stack from '@mui/material/Stack'
// import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import ZuDataTable from 'components/ZeroUno/ZuDataTable'

// zerouno
import projectService from 'services/project.service'
import { useParams, Link } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import RegistryNav from './RegistryNav'
import utils from 'utils/utils.js'

export default function ProjectTable() {
  const [objId, setObjId] = useState(0)
  const { idRegistry } = useParams()
  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const [openConfirm, setOpenConfirm] = useState(false)
  const { data: objects, isLoading, error } = projectService.findAll(idRegistry)

  // Delete
  const deleteObject = () => {
    setOpenConfirm(false)
    projectService.remove(idRegistry, objId).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        projectService.refresh(idRegistry)
        projectService.refresh()
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const columns = [
    ...(!idRegistry
      ? [
        {
          header: 'cliente',
          accessorFn: d => d.registry.businessName.trim()
        }
      ]
      : []),
    {
      header: 'nome',
      accessorKey: 'name'
    },
    {
      header: 'importo',
      accessorFn: d => {
        return d.price ? utils.formatFloat(d.price, 2) : '-'
      }
    },
    {
      header: 'inizio',
      accessorFn: d => {
        return d.startDate ? utils.formatDate(d.startDate, 'dd/MM/yyyy') : '-'
      }
    },
    {
      header: 'fine',
      accessorFn: d => {
        return d.endDate ? utils.formatDate(d.endDate, 'dd/MM/yyyy') : '-'
      }
    },
    {
      header: 'azioni',
      enableSorting: false,
      enableColumnFilter: false,
      width: 100,
      accessorFn: row => (
        <Grid container spacing={1}>
          <Grid item>
            <MDButton
              color='info'
              size='small'
              iconOnly
              component={Link}
              to={`/projects/${row.id}/attachments/`}
            >
              <Icon>attachment</Icon>
            </MDButton>
          </Grid>
          <Grid item>
            <MDButton
              color='info'
              size='small'
              iconOnly
              component={Link}
              to={`/registries/${row.registry.id}/projects/${row.id}`}
            >
              <Icon>edit</Icon>
            </MDButton>
          </Grid>
          <Grid item>
            <MDButton
              iconOnly
              color='error'
              size='small'
              onClick={() => {
                setObjId(row.id)
                setOpenConfirm(true)
              }}
            >
              <Icon>delete</Icon>
            </MDButton>
          </Grid>
        </Grid>
      )
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={idRegistry ? 10 : 12}>
            <Card>
              <ZUTitleForm bgColor='warning' title='Servizi' icon='settings' />
              {idRegistry && (
                <MDBox p={3} lineHeight={1}>
                  <MDButton
                    color='secondary'
                    component={Link}
                    to='/projects'
                    sx={{ m: 1 }}
                  >
                    tutti i servizi
                  </MDButton>
                  <MDButton
                    color='info'
                    component={Link}
                    to={`/registries/${idRegistry}/projects/new`}
                    m={1}
                  >
                    nuovo
                  </MDButton>
                </MDBox>
              )}
              <ZuDataTable table={{ columns: columns, rows: objects }} />
            </Card>
          </Grid>
          {idRegistry && (
            <Grid item xs={12} sm={12} md={2}>
              <RegistryNav id={idRegistry} />
            </Grid>
          )}
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>
            Conferma
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
