'use strict'
import { useEffect, useState } from 'react'
// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
// import CheckBoxIcon from '@mui/icons-material/CheckBox'
// import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
// import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'
// import MDDropdown from 'components/MDDropdown'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import ZuDataTable from 'components/ZeroUno/ZuDataTable'

// zerouno
import orderService from 'services/order.service'
import { useLocation, useParams, Link } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils.js'
import RegistryNav from './RegistryNav'

export default function OrderTable() {
  const location = useLocation()

  const { idRegistry } = useParams()
  const [objId, setObjId] = useState(0)
  const [objects, setObjects] = useState([])
  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const [openConfirm, setOpenConfirm] = useState(false)

  useEffect(() => {
    fetchObjects()
  }, [location])

  const fetchObjects = () => {
    orderService.findAll(idRegistry).then(
      response => {
        setObjects(response.data)
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const makeInvoices = () => {
    console.log('makeInvoices: ')
    orderService.makeInvoices().then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  // Delete
  const deleteObject = () => {
    setOpenConfirm(false)
    orderService.remove(objId).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        fetchObjects()
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const columns = [
    ...(!idRegistry
      ? [
        {
          header: 'cliente',
          accessorFn: d => d.registry.businessName.trim()
        }
      ]
      : []),
    {
      header: 'Indirizzo',
      accessorFn: d => {
        return `${d.address.address} ${d.address.civicNumber} - ${d.address.city}`
      }
    },
    {
      header: 'Data',
      accessorFn: d => {
        return utils.formatDate(d.date, 'dd/MM/yyyy')
      }
    },
    {
      header: 'azioni',
      enableSorting: false,
      enableColumnFilter: false,
      Cell: ({ row }) => (
        <Grid container spacing={1}>
          <Grid item>
            <MDButton
              color='info'
              size='small'
              iconOnly
              component={Link}
              to={`/registries/${row.original.registryId}/orders/${row.original.id}`}
            >
              <Icon>edit</Icon>
            </MDButton>
          </Grid>
          <Grid item>
            <MDButton
              iconOnly
              color='error'
              size='small'
              onClick={() => {
                setObjId(row.original.id)
                setOpenConfirm(true)
              }}
            >
              <Icon>delete</Icon>
            </MDButton>
          </Grid>
        </Grid>
      )
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={idRegistry ? 10 : 12}>
            <Card>
              <ZUTitleForm bgColor='warning' title='Ordini' icon='settings' />

              <MDBox p={3} lineHeight={1}>
                {idRegistry && (
                  <MDButton
                    color='info'
                    component={Link}
                    to={`/registries/${idRegistry}/orders/new`}
                    m={1}
                  >
                    nuovo
                  </MDButton>
                )}
                {!idRegistry && (
                  <MDButton
                    color='success'
                    onClick={() => makeInvoices()}
                    m={1}
                  >
                    genera fatture del mese
                  </MDButton>
                )}
              </MDBox>
              <ZuDataTable table={{ columns: columns, rows: objects }} />
            </Card>
          </Grid>
          {idRegistry && (
            <Grid item xs={12} sm={12} md={2}>
              <RegistryNav id={idRegistry} />
            </Grid>
          )}
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione ordine?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>
            Conferma
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
