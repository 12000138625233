import React, { useEffect } from 'react'
// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import ZuDataTable from 'components/ZeroUno/ZuDataTable'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'

// zerouno
import enumerationService from 'services/enumeration.service'
import { Link } from 'react-router-dom'

export default function EnumerationTable() {
  const [objId, setObjId] = React.useState(0)
  const [showSnackbar, setShowSnackbar] = React.useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const { data: objects, isLoading, error } = enumerationService.getAll()

  const deleteObject = () => {
    setOpenConfirm(false)
    enumerationService.remove(objId).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const columns = [
    {
      header: 'descrizione',
      accessorKey: 'description'
    },
    {
      header: 'tipo',
      accessorKey: 'type'
    },
    {
      header: 'ordine',
      accessorKey: 'order'
    },
    {
      header: 'azioni',
      enableSorting: false,
      enableColumnFilter: false,
      width: 100,
      accessorKey: 'id',
      Cell: ({ cell }) => (
        <Grid container spacing={1}>
          <Grid item>
            <MDButton
              color='info'
              size='small'
              iconOnly
              component={Link}
              to={'/enumerations/' + cell.getValue()}
            >
              <Icon>edit</Icon>
            </MDButton>
          </Grid>
          {/* <Grid item>
            <MDButton
              iconOnly
              color='error'
              size='small'
              onClick={() => {
                setObjId(value)
                setOpenConfirm(true)
              }}
            >
              <Icon>delete</Icon>
            </MDButton>
          </Grid> */}
        </Grid>
      )
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <ZUTitleForm bgColor='warning' title='Descrizioni' icon='settings' />

          </MDBox>
          <MDBox p={3} lineHeight={1}>
            <MDButton
              color='info'
              component={Link}
              to='/enumerations/new'
              m={1}
            >
              nuova
            </MDButton>
          </MDBox>
          <ZuDataTable table={{ columns: columns, rows: objects }} />
        </Card>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>
            Conferma
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
