'use strict'

import React, { useEffect } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
// import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
// zerouno
import deviceService from 'services/device.service'
import registryService from 'services/registry.service'
import addressService from 'services/address.service'
// import enumerationService from 'services/enumeration.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import RegistryNav from './RegistryNav'
import { DateTime } from 'luxon'
import utils from 'utils/utils.js'
import { config } from 'variables/Config.js'
import { Autocomplete } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import MDTypography from 'components/MDTypography'
import deviceCustomerService from 'services/device-customer.service'

export default function DeviceCustomerForm() {
  const { idObj } = useParams()
  const { idRegistry } = useParams()
  const { idProject } = useParams()
  const [obj, setObj] = React.useState({})
  const [device, setDevice] = React.useState({})
  const [address, setAddress] = React.useState({})
  const [showSnackbar, setShowSnackbar] = React.useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue
  } = useForm()
  // const [valueD, setValueD] = React.useState(new Date())
  // const [valueE, setValueE] = React.useState(new Date())
  const navigate = useNavigate()
  const [file, setFile] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [devices, setDevices] = React.useState([])
  const [addresses, setAddresses] = React.useState([])

  useEffect(() => {
    fetchObject()
    fetchDevices()
    fetchAddresses()
  }, [])

  useEffect(() => {
    reset({ ...obj })
  }, [obj])

  const fetchObject = () => {
    if (idObj === 'new') {
      setObj({
        activationDate: DateTime.local().toISODate(),
        registryId: idRegistry,
        price: 0.0,
        disactivationDate: null
      })
    } else {
      deviceCustomerService.findById(idRegistry, idObj).then(
        response => {
          setObj(response.data)
          setDevice(response.data.device)
          setAddress(response.data.address)
          setFile(response.data.file)
        },
        error => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        }
      )
    }
  }

  const fetchDevices = () => {
    deviceService.findNotAssigned(idRegistry).then(
      response => {
        setDevices(response.data)
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const fetchAddresses = () => {
    addressService.getByRegistry(idRegistry).then(
      response => {
        setAddresses(response.data)
      },
      error => showError(error)
    )
  }

  const onSubmit = data => {
    data.file = file
    data.deviceId = device.id
    data.device = device
    data.addressId = address.id
    data.address = address
    data.price = String(data.price).replace('.', '').replace(',', '.')
    if (idObj === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = data => {
    // data.image = obj.image
    deviceCustomerService.create(idRegistry, data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate(`/registries/${idRegistry}/device-customers`)
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const updateObj = (data, id) => {
    // data.image = obj.image
    deviceCustomerService.update(idRegistry, id, data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate(`/registries/${idRegistry}/device-customers`)
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const uploadFile = files => {
    const newFile = files[0]
    if (newFile) {
      if (newFile.size <= 5 * 1024 * 1000) {
        setLoading(true)
        const formData = new FormData()
        formData.append('file', newFile)
        deviceCustomerService.upload(idRegistry, formData, idProject).then(
          response => {
            setLoading(false)
            const filename = response.data
            setFile(filename)
          },
          error => {
            setLoading(false)
            setShowSnackbar({
              sh: true,
              msg: 'errore upload file ' + error.data.message,
              color: 'error'
            })
          }
        )
      } else {
        setShowSnackbar({ sh: true, msg: 'file troppo grande', color: 'error' })
      }
    }
  }

  const removeFile = () => {
    const formData = new FormData()
    formData.delete('file')
    setFile('')
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm
                  bgColor='warning'
                  title='Assegna prodotto'
                  icon='settings'
                />

                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl fullWidth required error={!!errors.typeId}>
                        <Controller
                          name='device'
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <Autocomplete
                              options={devices}
                              value={device || null}
                              onChange={(_, item) => {
                                setDevice(item)
                                onChange(item ? item.id : null)
                              }}
                              getOptionLabel={item =>
                                item.id
                                  ? `${item.model?.name} | s.n.: ${item.serial
                                  }${item.macAddress
                                    ? ' | mac: ' + item.macAddress
                                    : ''
                                  }`
                                  : ''}
                              isOptionEqualToValue={(item, val) => {
                                return item.id == val.id
                              }}
                              renderInput={params => (
                                <MDInput
                                  {...params}
                                  fullWidth
                                  required
                                  label='Prodotto'
                                  error={!!errors.device}
                                />
                              )}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} />
                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        autoFocus
                        label='Email'
                        error={!!errors.email}
                        {...register('email', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        autoFocus
                        label='Password'
                        error={!!errors.password}
                        {...register('password', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        autoFocus
                        label='Ip'
                        error={!!errors.ip}
                        {...register('ip', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='date'
                        required
                        label='data attivazione'
                        error={!!errors.activationDate}
                        {...register('activationDate', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        autoFocus
                        required
                        label='Prezzo'
                        error={!!errors.price}
                        {...register('price', {
                          required: false,
                          pattern: utils.floatPattern
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='date'

                        label='data disattivazione'
                        error={!!errors.disactivationDate}
                        {...register('disactivationDate', { required: false })}
                      />

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} marginTop='1rem'>
                      <MDTypography>Indirizzo</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Controller
                        name='address'
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Autocomplete
                            options={addresses}
                            value={address || null}
                            onChange={(_, item) => {
                              setAddress(item)
                              onChange(item ? item.id : null)
                            }}
                            getOptionLabel={item =>
                              item.id
                                ? `${item.address} ${item.civicNumber} - ${item.city}`
                                : ''}
                            isOptionEqualToValue={(item, val) => {
                              return item.id == val.id
                            }}
                            renderInput={params => (
                              <MDInput
                                {...params}
                                fullWidth
                                required
                                label='Indirizzo'
                                error={!!errors.address}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={4} md={3}>
                            <Controller
                              control={control}
                              name='main'
                              render={({ field: { value, onChange } }) => (
                                <FormControlLabel
                                  control={<Switch checked={value || false} onChange={onChange} />}
                                  label='indirizzo principale'
                                />
                              )}
                            />
                          </Grid> */}
                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        multiline
                        rows={3}
                        required={false}
                        label='note'
                        error={!!errors.notes}
                        {...register('notes', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <Stack spacing={2}>
                        {(file === null ||
                          file === undefined ||
                          file === '') && (
                            <label htmlFor='contained-button-file'>
                              <MDInput
                                accept='*'
                                id='contained-button-file'
                                type='file'
                                sx={{ display: 'none' }}
                                onChange={target =>
                                  uploadFile(target.target.files)}
                              />
                              <MDButton
                                variant='gradient'
                                color='info'
                                component='span'
                                style={{ marginTop: '30px' }}
                              >
                                Upload documento (max 5Mb)
                              </MDButton>
                            </label>
                          )}
                        {loading && (
                          <CircularProgress color='primary' sx={{ m: 2 }} />
                        )}
                        {file && (
                          <MDButton
                            color='primary'
                            href={`${config.SERVER_URL}/registries/${idRegistry}/devicecustomers/download/${file}`}
                            target='_blank'
                            mb={2}
                          >
                            download
                          </MDButton>
                        )}
                        {file && (
                          <MDButton color='error' onClick={() => removeFile()}>
                            elimina file
                          </MDButton>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient'
                    color='secondary'
                    component={Link}
                    to={`/registries/${idRegistry}/device-customers`}
                  >
                    Indietro
                  </MDButton>
                  <MDButton
                    type='submit'
                    variant='gradient'
                    color='success'
                    sx={{ ml: 1 }}
                  >
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
