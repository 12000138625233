'use strict'

import React, { useEffect, useState } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDSnackbar from 'components/MDSnackbar'

// zerouno
import ddtService from 'services/ddt.service'
import registryService from 'services/registry.service'
import addressService from 'services/address.service'
import deviceCustomerService from 'services/device-customer.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import RegistryNav from './RegistryNav'
import { DateTime } from 'luxon'
import utils from 'utils/utils.js'
import { config } from 'variables/Config.js'
import { DatePicker } from '@mui/x-date-pickers'
import ZuDataTable from 'components/ZeroUno/ZuDataTable'
import MDTypography from 'components/MDTypography'
import ZUAutoComplete from 'components/ZeroUno/ZUAutoComplete'

export default function DdtForm() {
  const { idObj } = useParams()
  const { idRegistry } = useParams()
  const { idProject } = useParams()
  const [registry, setRegistry] = useState({})
  const [obj, setObj] = React.useState({
    dateDdt: DateTime.local().toISODate(),
    registry: null,
    registryId: null,
    packages: 1
  })
  const [registries, setRegistries] = React.useState([])
  const [addresses, setAddresses] = React.useState([])
  const [devices, setDevices] = React.useState([])
  const [devicesDdt, setDevicesDdt] = React.useState([])
  const [showSnackbar, setShowSnackbar] = React.useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue
  } = useForm()
  const navigate = useNavigate()
  const [file, setFile] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const watchRegistry = watch('registry')
  const watchAddress = watch('address')

  useEffect(() => {
    fetchObject()
    fetchRegistries()
  }, [])

  useEffect(() => {
    reset({ ...obj })
  }, [obj])

  useEffect(() => {
    if (watchRegistry?.id) {
      fetchAddresses(watchRegistry.id)
    } else {
      setAddresses([])
      setDevices([])
      setDevicesDdt([])
    }
  }, [watchRegistry])

  useEffect(() => {
    if (watchAddress?.id) {
      fetchDevices(watchRegistry.id, watchAddress.id)
    } else {
      setDevices([])
      setDevicesDdt([])
    }
  }, [watchAddress])

  const fetchObject = () => {
    if (idObj !== 'new') {
      ddtService.findById(idObj).then(
        (response) => {
          setObj(response.data)
          setRegistry(response.data.registry)
          setDevicesDdt(response.data.devices)
          setFile(response.data.file)
        },
        (error) => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        }
      )
    }
  }

  const fetchRegistries = () => {
    registryService.findAll().then(
      (response) => {
        setRegistries(response.data)
      },
      (error) => showError(error)
    )
  }

  const fetchAddresses = (registryId) => {
    addressService.getByRegistry(registryId).then(
      (response) => {
        setAddresses(response.data)
      },
      (error) => showError(error)
    )
  }

  const fetchDevices = (registryId, addressId) => {
    deviceCustomerService.findByRegistry(registryId, addressId).then(
      (response) => {
        setDevices(response.data.filter((x) => !x.ddtId))
      },
      (error) => showError(error)
    )
  }

  const onSubmit = (data) => {
    data.file = file
    data.registryId = watchRegistry.id
    data.registry = watchRegistry
    data.addressId = watchAddress.id
    data.address = watchAddress
    data.devices = devicesDdt
    if (idObj === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = (data) => {
    // data.image = obj.image
    ddtService.create(data).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate('/ddts')
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const addDevice = (id) => {
    const _deviceDdt = devicesDdt.concat(devices.filter((x) => x.id == id))
    setDevicesDdt(_deviceDdt)
    setDevices(devices.filter((x) => x.id != id))
  }

  const removeDevice = (id) => {
    const _devices = devices.concat(devicesDdt.filter((x) => x.id == id))
    setDevices(_devices)
    setDevicesDdt(devicesDdt.filter((x) => x.id != id))
  }

  const updateObj = (data, id) => {
    // data.image = obj.image
    ddtService.update(id, data).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate('/ddts')
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const uploadFile = (files) => {
    const newFile = files[0]
    if (newFile) {
      if (newFile.size <= 5 * 1024 * 1000) {
        setLoading(true)
        const formData = new FormData()
        formData.append('file', newFile)
        ddtService.upload(formData).then(
          (response) => {
            setLoading(false)
            const filename = response.data
            setFile(filename)
          },
          (error) => {
            setLoading(false)
            setShowSnackbar({
              sh: true,
              msg: 'errore upload file ' + error.data.message,
              color: 'error'
            })
          }
        )
      } else {
        setShowSnackbar({ sh: true, msg: 'file troppo grande', color: 'error' })
      }
    }
  }

  const removeFile = () => {
    const formData = new FormData()
    formData.delete('file')
    setFile('')
  }

  const columns = [
    {
      header: 'SN',
      accessorKey: 'device.serial',
      width: 150
    },
    {
      header: 'Mac',
      accessorKey: 'device.macAddress',
      width: 150
    },
    {
      header: 'Modello',
      accessorKey: 'device.model.name',
      width: 150
    },
    {
      header: 'Prezzo',
      accessorKey: 'price',
      Cell: ({ cell }) => utils.formatFloat(cell.getValue(), 2)
    },
    {
      header: 'azioni',
      enableSorting: false,
      enableColumnFilter: false,
      width: 100,
      accessorKey: 'id',
      Cell: ({ cell }) => (
        <Grid
          container
          spacing={1}
        >
          <Grid item>
            <MDButton
              iconOnly
              color='success'
              size='small'
              onClick={() => {
                addDevice(cell.getValue())
              }}
            >
              <Icon>add</Icon>
            </MDButton>
          </Grid>
        </Grid>
      )
    }
  ]

  const columnsDeviceDdt = [
    {
      header: 'SN',
      accessorKey: 'device.serial',
      width: 150
    },
    {
      header: 'Mac',
      accessorKey: 'device.macAddress',
      width: 150
    },
    {
      header: 'Modello',
      accessorKey: 'device.model.name',
      width: 150
    },
    {
      header: 'Prezzo',
      accessorKey: 'price',
      Cell: ({ cell }) => utils.formatFloat(cell.getValue(), 2)
    },
    {
      header: 'azioni',
      enableSorting: false,
      enableColumnFilter: false,
      width: 100,
      accessorKey: 'id',
      Cell: ({ cell }) => (
        <Grid
          container
          spacing={1}
        >
          <Grid item>
            <MDButton
              iconOnly
              color='error'
              size='small'
              onClick={() => {
                removeDevice(cell.getValue())
              }}
            >
              <Icon>delete</Icon>
            </MDButton>
          </Grid>
        </Grid>
      )
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        pt={6}
        pb={3}
      >
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm
                  bgColor='warning'
                  title='DDT prodotti'
                  icon='settings'
                />

                <MDBox p={2}>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <ZUAutoComplete
                        disabled={!!obj.number}
                        control={control}
                        label='Cliente'
                        name='registry'
                        options={registries}
                        defaultValue={obj.registry}
                        error={!!errors.model}
                        required
                        rules={{ required: true }}
                        getOptionLabel={(item) => item.businessName ?? ''}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <ZUAutoComplete
                        disabled={!!obj.number || !watchRegistry?.id}
                        control={control}
                        label='Indirizzo'
                        name='address'
                        options={addresses}
                        defaultValue={obj.address}
                        error={!!errors.model}
                        required
                        rules={{ required: true }}
                        getOptionLabel={(item) =>
                          item.id
                            ? `${item.address} ${item.civicNumber} - ${item.city}`
                            : ''}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={3}
                    >
                      <MDInput
                        fullWidth
                        type='date'
                        required
                        label='data'
                        error={!!errors.dateDdt}
                        {...register('dateDdt', { required: true })}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={2}
                    >
                      <MDInput
                        fullWidth
                        type='number'
                        label='numero'
                        min={1}
                        required
                        error={!!errors.number}
                        {...register('number', {
                          required: true
                        })}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={3}
                    >
                      <MDInput
                        fullWidth
                        type='number'
                        label='n° colli'
                        min={1}
                        error={!!errors.packages}
                        {...register('packages', {
                          required: false
                        })}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                    >
                      <MDInput
                        fullWidth
                        type='text'
                        label='porto'
                        error={!!errors.port}
                        {...register('port', {
                          required: false
                        })}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                    >
                      <MDInput
                        fullWidth
                        type='text'
                        label='corriere'
                        error={!!errors.carrier}
                        {...register('carrier', {
                          required: false
                        })}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <MDInput
                        fullWidth
                        type='text'
                        label='causale'
                        error={!!errors.causal}
                        {...register('causal', {
                          required: false
                        })}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <MDTypography variant='h6'>
                        Prodotti disponibili
                      </MDTypography>

                      <ZuDataTable
                        table={{ columns: columns, rows: devices }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <MDTypography variant='h6'>Righe ddt</MDTypography>

                      <ZuDataTable
                        table={{ columns: columnsDeviceDdt, rows: devicesDdt }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                    >
                      <Stack spacing={2}>
                        {idObj != 'new' && (
                          <MDButton
                            color='secondary'
                            href={`${config.SERVER_URL}/ddts/download/${idObj}`}
                            target='_blank'
                            mb={2}
                          >
                            Download ddt
                          </MDButton>
                        )}
                        {(file === null ||
                          file === undefined ||
                          file === '') && (
                            <label htmlFor='contained-button-file'>
                              <MDInput
                                accept='*'
                                id='contained-button-file'
                                type='file'
                                sx={{ display: 'none' }}
                                onChange={(target) =>
                                  uploadFile(target.target.files)}
                              />
                              <MDButton
                                variant='gradient'
                                color='info'
                                component='span'
                                style={{ marginTop: '30px', width: '100%' }}
                              >
                                Upload ddt firmato (max 5Mb)
                              </MDButton>
                            </label>
                          )}
                        {loading && (
                          <CircularProgress
                            color='primary'
                            sx={{ m: 2 }}
                          />
                        )}
                        {file && (
                          <MDButton
                            color='primary'
                            href={`${config.SERVER_URL}/ddts/download-firmato/${file}`}
                            target='_blank'
                            mb={2}
                          >
                            Download ddt firmato
                          </MDButton>
                        )}
                        {file && (
                          <MDButton
                            color='error'
                            onClick={() => removeFile()}
                          >
                            elimina ddt firmato
                          </MDButton>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient'
                    color='secondary'
                    component={Link}
                    to='/ddts'
                  >
                    Indietro
                  </MDButton>
                  <MDButton
                    type='submit'
                    variant='gradient'
                    color='success'
                    sx={{ ml: 1 }}
                  >
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
