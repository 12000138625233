'use strict'

import { useEffect, useState, useContext, useRef } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Select from '@mui/material/Select'
import Dialog from '@mui/material/Dialog'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Tooltip,
  Link as MuiLink
} from '@mui/material'
// import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
// import FormHelperText from '@mui/material/FormHelperText'
// import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
// import MDAlert from 'components/MDAlert'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'

// zerouno
import registryService from 'services/registry.service'
import orderService from 'services/order.service'
import addressService from 'services/address.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import ZUAutoComplete from 'components/ZeroUno/ZUAutoComplete'
import utils from 'utils/utils'
import { DateTime } from 'luxon'
import ZuDataTable from 'components/ZeroUno/ZuDataTable'
import RegistryNav from './RegistryNav'

export default function OrderForm() {
  const { idObj } = useParams()
  const { idRegistry } = useParams()
  const [obj, setObj] = useState({})
  const [registry, setRegistry] = useState({})
  const [addresses, setAddresses] = useState([])
  const [rows, setRows] = useState([])
  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue
  } = useForm({
    date: DateTime.local().toISODate(),
    registryId: idRegistry,
    payment: 'rid',
    address: null
  })
  const navigate = useNavigate()
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [rowOrder, setRowOrder] = useState({})
  const [openConfirm, setOpenConfirm] = useState(false)
  const [rowOrderId, setRowOrderId] = useState(-1)

  useEffect(() => {
    fetchObject()
    fetchRegistry(idRegistry)
    fetchAddresses()
  }, [])

  useEffect(() => {
    reset({ ...obj })
  }, [obj])

  const fetchObject = () => {
    if (idObj === 'new') {
      setObj({
        date: DateTime.local().toISODate(),
        registryId: idRegistry,
        payment: registry?.payment || 'rid',
        address: null
      })
    } else {
      orderService.findById(idObj).then(
        response => {
          setObj(response.data)
          setRegistry(response.data.registry)
          setRows(response.data.rows)
        },
        error => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        }
      )
    }
  }

  const fetchRegistry = registryId => {
    registryService.findById(registryId).then(
      response => {
        setRegistry(response.data)
        if (idObj === 'new' && obj.payment) {
          setObj({ ...obj, payment: response.data.payment })
        }
      },
      error =>
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
    )
  }

  const fetchAddresses = () => {
    addressService.getByRegistry(idRegistry).then(
      response => {
        setAddresses(response.data)
      },
      error =>
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
    )
  }

  const columns = [
    {
      header: 'Codice',
      accessorKey: 'code',
      size: 10
    },
    {
      header: 'Descrizione',
      accessorKey: 'description',
      size: 10
    },
    {
      header: 'Qta',
      accessorKey: 'quantity',
      size: 10
    },
    {
      header: 'Prezzo',
      size: 10,
      accessorFn: row =>
        '€ ' + row.amount.toFixed(2).toLocaleString('it').replace('.', ',')
    },
    {
      header: 'Iva',
      size: 10,
      accessorFn: row => row.vat + '%'
    },
    {
      header: 'Totale',
      size: 10,
      accessorFn: row =>
        '€ ' +
        (row.amount * row.quantity * (1 + row.vat / 100))
          .toFixed(2)
          .toLocaleString('it')
          .replace('.', ',')
    },
    {
      header: 'Periodicità',
      size: 10,
      accessorFn: row =>
        utils.periodicities.find(x => x.id == row.periodicity)?.label
    },
    {
      header: 'durata',
      size: 10,
      accessorFn: row => row.duration
    },
    {
      header: 'Data attivazione',
      size: 10,
      accessorFn: d => {
        return d.activationDate
          ? utils.formatDate(d.activationDate, 'dd/MM/yyyy')
          : ''
      }
    },
    {
      header: 'Data scadenza',
      size: 10,
      accessorFn: d => {
        return d.expireDate ? utils.formatDate(d.expireDate, 'dd/MM/yyyy') : ''
      }
    },
    {
      header: 'azioni',
      enableSorting: false,
      enableColumnFilter: false,
      size: 10,
      width: 100,
      Cell: ({ row }) => (
        <>
          <MDButton
            color='info'
            size='small'
            iconOnly
            sx={{ mr: 1 }}
            onClick={() => {
              setRowOrderId(row.index)
              setRowOrder(row.original)
              setCreateModalOpen(true)
            }}
          >
            <Icon>edit</Icon>
          </MDButton>
          <MDButton
            iconOnly
            color='error'
            size='small'
            onClick={() => {
              setRowOrderId(row.index)
              setOpenConfirm(true)
            }}
          >
            <Icon>delete</Icon>
          </MDButton>
        </>
      )
    }
  ]

  const handleSavedRow = values => {
    values.amount = parseFloat(values.amount)
    values.quantity = parseFloat(values.quantity)
    if (values.id) {
      setRows(
        rows.map((r, i) => {
          if (rowOrderId == i) {
            return values
          } else {
            return r
          }
        })
      )
      setRowOrderId(-1)
    } else {
      setRows([...rows, values])
    }
  }

  const deleteRow = () => {
    const _rows = [...rows]
    if (rowOrderId > -1) {
      _rows.splice(rowOrderId, 1)
      setRows(_rows)
    }
    setOpenConfirm(false)
    setRowOrderId(-1)
  }

  const addRow = () => {
    setRowOrder({
      vat: 22.0,
      activationDate: DateTime.local().toISODate(),
      expireDate: null,
      duration: 0
    })
    setCreateModalOpen(true)
  }

  const onSubmitOrder = data => {
    data.addressId = data.address.id
    data.rows = rows
    if (idObj === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = data => {
    orderService.create(data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate(`/registries/${idRegistry}/orders`)
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const updateObj = (data, id) => {
    console.log('data: ', data)
    orderService.update(id, data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate(`/registries/${idRegistry}/orders`)
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  let help =
    'la generazione della fattura avviene in funzione della data di inizio e della periodicità.\n'
  help =
    help +
    '1) le righe con una tantum vengono inserite se la data è nel mese in cui viene emessa la fattura\n'
  help =
    help +
    '2) le righe con periodicità vengono inserite se la data di inizio è precedente a quello in cui viene emessa la fattura\n'
  help = help + 'e fatturate in anticipo fino alla data di scadenza'

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={10}>
            <form onSubmit={handleSubmit(onSubmitOrder)}>
              <Card>
                <ZUTitleForm bgColor='warning' title='Ordine' icon='settings' />
                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={11} sm={11} md={11}>
                      <MDTypography variant='h5' fontWeight='medium'>
                        Cliente: {registry.businessName}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}>
                      <Tooltip title={help} arrow>
                        <Icon color='info' fontSize='large'>
                          help
                        </Icon>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <ZUAutoComplete
                        control={control}
                        label='indirizzo'
                        name='address'
                        options={addresses}
                        defaultValue={obj.address || null}
                        error={!!errors.address}
                        required
                        rules={{ required: true }}
                        getOptionLabel={item =>
                          item.id
                            ? `${item.address} ${item.civicNumber} - ${item.city}`
                            : ''}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='date'
                        required
                        label='data'
                        error={!!errors.date}
                        {...register('date', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <ZUAutoComplete
                        control={control}
                        label='modalità pagamento'
                        name='payment'
                        options={['rid', 'dir']}
                        defaultValue={obj.payment || 'rid'}
                        error={!!errors.payment}
                        required
                        rules={{ required: true }}
                        getOptionLabel={item =>
                          item == 'dir' ? 'rimessa diretta' : 'rid ssd'}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <ZuDataTable
                        table={{ columns: columns, rows: rows }}
                        renderTopToolbarCustomActions={() => (
                          <MDButton
                            color='secondary'
                            onClick={() => {
                              addRow()
                            }}
                            variant='contained'
                          >
                            nuovo
                          </MDButton>
                        )}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient'
                    color='secondary'
                    component={Link}
                    to={`/registries/${idRegistry}/orders`}
                  >
                    Indietro
                  </MDButton>
                  <MDButton
                    type='submit'
                    variant='gradient'
                    color='success'
                    sx={{ ml: 1 }}
                  >
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <RegistryNav id={idRegistry} />
          </Grid>
        </Grid>
      </MDBox>
      {createModalOpen && (
        <CreateOrderRowModal
          open
          onClose={() => setCreateModalOpen(false)}
          OnSaved={handleSavedRow}
          rowOrder={rowOrder}
        />
      )}
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione riga?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteRow} autoFocus>
            Conferma
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}

export const CreateOrderRowModal = ({ open, onClose, OnSaved, rowOrder }) => {
  console.log('rowOrder: ', rowOrder)
  const [rowObj, setRowObj] = useState({
    ...rowOrder,
    amount: utils.formatFloat(rowOrder.amount, 2),
    quantity: utils.formatFloat(rowOrder.quantity, 2)
  })

  const {
    register,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
    handleSubmit
  } = useForm()

  useEffect(() => { }, [])

  useEffect(() => {
    reset({ ...rowObj })
  }, [rowObj])

  const onSubmitDialog = data => {
    data.quantity = String(data.quantity).replace(',', '.')
    data.amount = String(data.amount).replace(',', '.')
    OnSaved(data)
    reset()
    onClose()
  }

  return (
    <Dialog open={open}>
      <form onSubmit={handleSubmit(onSubmitDialog)}>
        <DialogTitle textAlign='center'>Crea riga</DialogTitle>
        <DialogContent>
          <MDBox pt={3} pb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <MDInput
                  fullWidth
                  type='text'
                  autoFocus
                  label='Codice'
                  error={!!errors.code}
                  {...register('code', { required: false })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <MDInput
                  fullWidth
                  type='text'
                  autoFocus
                  label='Descrizione'
                  error={!!errors.description}
                  {...register('description', { required: false })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <MDInput
                  fullWidth
                  type='number'
                  defaultValue={1}
                  required
                  label='Quantità'
                  error={!!errors.quantity}
                  {...register('quantity', {
                    required: true,
                    pattern: utils.floatPattern
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl fullWidth required error={!!errors.vat}>
                  <InputLabel>iva</InputLabel>
                  <Select
                    label='iva'
                    defaultValue={rowObj.vat || undefined}
                    {...register('vat', { required: true })}
                  >
                    <MenuItem key={0} value={0.0}>
                      0 %
                    </MenuItem>
                    <MenuItem key={4} value={4.0}>
                      4 %
                    </MenuItem>
                    <MenuItem key={10} value={10.0}>
                      10 %
                    </MenuItem>
                    <MenuItem key={22} value={22.0}>
                      22 %
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MDInput
                  fullWidth
                  type='text'
                  required
                  label='Prezzo una tantum o prezzo mensile'
                  error={!!errors.amount}
                  {...register('amount', {
                    required: true,
                    pattern: utils.floatPattern
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl fullWidth required error={!!errors.periodicity}>
                  <InputLabel>Periodicità</InputLabel>
                  <Select
                    label='Periodicità'
                    type='number'
                    defaultValue={rowObj.periodicity || 0}
                    {...register('periodicity', { required: true })}
                  >
                    {utils.periodicities.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MDInput
                  fullWidth
                  type='date'
                  required
                  label='data attivazione (mese prima fattura)'
                  error={!!errors.activationDate}
                  {...register('activationDate', { required: true })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MDInput
                  fullWidth
                  type='date'
                  label='data scadenza'
                  error={!!errors.expireDate}
                  {...register('expireDate', { required: false })}
                />

              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MDInput
                  fullWidth
                  type='number'
                  required
                  label='durata (mesi)'
                  error={!!errors.duration}
                  {...register('duration', {
                    required: true,
                    min: 0
                  })}
                />
              </Grid>
            </Grid>
          </MDBox>
        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
          <MDButton onClick={onClose}>Annulla</MDButton>
          <MDButton type='submit' color='secondary' variant='contained'>
            Salva
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
