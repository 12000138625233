'use strict'

import React, { useEffect } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
// import Select from '@mui/material/Select'
// import Icon from '@mui/material/Icon'
// import InputLabel from '@mui/material/InputLabel'
// import MenuItem from '@mui/material/MenuItem'
// import FormControl from '@mui/material/FormControl'
// import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'

// zerouno
import contactService from 'services/contact.service'
import enumerationService from 'services/enumeration.service'
import userService from 'services/user.service'
import authService from 'services/auth.service'
import tagService from 'services/tag.service'

// import uploadService from 'services/upload.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import RegistryNav from './RegistryNav'
import {
  FormControl,
  InputLabel,
  RadioGroup,
  Select,
  Radio,
  Autocomplete,
  MenuItem,
  CircularProgress,
  Checkbox,
  Chip,
  Icon
} from '@mui/material'
import { DateTime } from 'luxon'

export default function ContactForm() {
  const { idObj } = useParams()
  const [obj, setObj] = React.useState({ ticketPermissionId: '' })
  const [showSnackbar, setShowSnackbar] = React.useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset
  } = useForm()
  const idRegistry = new URLSearchParams(useLocation().search).get('idRegistry')
  const {
    data: enums,
    isLoading: loadEnum,
    error: errEnums
  } = enumerationService.findByType('permission')
  const [contactMode, setContactMode] = React.useState('existing')
  const [users, setUsers] = React.useState([])
  const [fetchedTags, setFetchedTags] = React.useState([])
  const [tags, setTags] = React.useState([])
  const [objTags, setObjTags] = React.useState([])
  const navigate = useNavigate()
  const [isLoadingSendMail, setIsLoadingSendMail] = React.useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = React.useState(false)
  const isVisibleEmailMessage = [11].includes(
    watch('ticketPermissionId', false)
  )

  useEffect(() => {
    fetchObject()
    fetchUsers()
    fetchTags()
  }, [])

  useEffect(() => {
    reset({ ...obj })
  }, [obj])

  const fetchObject = () => {
    if (idObj === 'new') {
      setObj({ ticketPermissionId: '', receiveNotification: false, tags: [] })
    } else {
      contactService.get(idObj).then(
        response => {
          setObj(response.data)
          if (response.data.tags) {
            setObjTags(response.data.tags)
          }
        },
        error => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        }
      )
    }
  }

  const fetchUsers = () => {
    userService.findAll().then(
      response => {
        setUsers(response.data)
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const fetchTags = () => {
    tagService.getAllTags().then(
      response => {
        setFetchedTags(response.data)
        setTags(response.data)
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const onClickContactRadio = mode => {
    setObj({
      ...obj,
      user: null,
      ticketPermissionId: '',
      notes: ''
    })
    setObjTags([])
    setContactMode(mode)
  }

  const sendPasswordMail = () => {
    setIsLoadingSendMail(true)
    authService.forgotPassword(obj.user.email).then(
      response => {
        setIsLoadingSendMail(false)
        setShowSnackbar({
          sh: true,
          msg: response.data.message,
          color: 'success'
        })
      },
      error => {
        setIsLoadingSendMail(false)
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const resetTagList = () => {
    setTags([...fetchedTags])
  }

  const handleClose = (event, reason) => {
    if (reason == 'blur') {
      resetTagList()
    }
  }

  const mergeLists = (firstArr, secondArr) => {
    const result = firstArr
    secondArr.forEach(el => {
      if (!result.map(x => x.name).includes(el.name)) {
        result.push(el)
      }
    })
    return result
  }

  const updateTagList = text => {
    if (fetchedTags.filter(x => x.name == text).length == 0) {
      setTags([
        ...fetchedTags,
        {
          name: text
        }
      ])
    } else {
      setTags([...fetchedTags])
    }
  }

  const onSubmit = data => {
    data.tags = objTags
    if (idObj === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = data => {
    setIsLoadingSubmit(true)
    contactService.create(data, idRegistry).then(
      response => {
        setIsLoadingSubmit(false)
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate(`/registries/${idRegistry}/contacts`)
      },
      error => {
        setIsLoadingSubmit(false)
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const updateObj = (data, id) => {
    setIsLoadingSubmit(true)
    contactService.update(id, data).then(
      response => {
        setIsLoadingSubmit(false)
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })

        if (idRegistry) {
          navigate(`/registries/${idRegistry}/contacts`)
        } else {
          navigate('/contacts')
        }
      },
      error => {
        setIsLoadingSubmit(false)
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={10}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm bgColor='warning' title='Contatto' icon='settings' />

                <MDBox p={2}>
                  {idObj === 'new' && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                        <FormControl fullWidth>
                          <RadioGroup
                            aria-labelledby='demo-radio-buttons-group-label'
                            defaultValue='existing'
                            name='radio-buttons-group'
                          >
                            <FormControlLabel
                              value='existing'
                              control={
                                <Radio
                                  onClick={() =>
                                    onClickContactRadio('existing')}
                                />
                              }
                              label='Seleziona un contatto'
                            />
                            {contactMode == 'existing' && (
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={3}>
                                  <Controller
                                    name='user'
                                    control={control}
                                    render={({
                                      field: { value, onChange }
                                    }) => (
                                      <Autocomplete
                                        options={users || []}
                                        value={obj.user}
                                        onChange={(_, item) => {
                                          setObj({
                                            ...obj,
                                            user: item,
                                            userId: item ? item.id : 'n'
                                          })
                                          onChange(item ? item.id : null)
                                        }}
                                        getOptionLabel={item =>
                                          item
                                            ? item.name +
                                            ' ' +
                                            item.surname +
                                            ' | ' +
                                            item.email
                                            : ''}
                                        isOptionEqualToValue={(item, val) => {
                                          return item.id == val.id
                                        }}
                                        renderInput={params => (
                                          <MDInput
                                            {...params}
                                            fullWidth
                                            required
                                            label='utente'
                                            error={!!errors.user}
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3}>
                                  <MDInput
                                    fullWidth
                                    type='text'
                                    required={false}
                                    label='ruolo'
                                    error={!!errors.contact?.notes}
                                    {...register('notes', {
                                      required: false
                                    })}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3}>
                                  <FormControl
                                    fullWidth
                                    required
                                    error={!!errors.role}
                                  >
                                    <InputLabel>Permessi tickets</InputLabel>
                                    <Select
                                      defaultValue=''
                                      label='Permessi'
                                      {...register('ticketPermissionId', {
                                        required: true
                                      })}
                                    >
                                      {enums &&
                                        enums.map(item => (
                                          <MenuItem
                                            key={item.id}
                                            value={item.id}
                                          >
                                            {item.description}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3}>
                                  {isVisibleEmailMessage && (
                                    <MDBox display='flex' alignItems='center'>
                                      <Checkbox
                                        defaultChecked={
                                          obj?.receiveNotification
                                        }
                                        {...register('receiveNotification', {
                                          required: false
                                        })}
                                      />
                                      <MDBox ml={1}>
                                        <MDTypography
                                          variant='caption'
                                          fontWeight='medium'
                                          color='text'
                                        >
                                          Notifiche tramite email
                                        </MDTypography>
                                      </MDBox>
                                    </MDBox>
                                  )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                  <Controller
                                    name='tags'
                                    control={control}
                                    render={({
                                      field: { value, onChange }
                                    }) => (
                                      <Autocomplete
                                        multiple
                                        value={objTags || []}
                                        options={tags.map(option => option)}
                                        getOptionLabel={item =>
                                          item.name ? item.name : ''}
                                        onChange={(_, item) => {
                                          const _temp = mergeLists(tags, item)
                                          setTags(_temp)
                                          setObjTags(item)
                                          onChange(item || [])
                                        }}
                                        onClose={handleClose}
                                        renderInput={params => (
                                          <MDInput
                                            {...params}
                                            fullWidth
                                            label='tag'
                                            onChange={e =>
                                              updateTagList(e.target.value)}
                                            error={!!errors.user}
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            )}
                            <FormControlLabel
                              style={{ marginTop: '1rem' }}
                              value='new'
                              control={
                                <Radio
                                  onClick={() => onClickContactRadio('new')}
                                />
                              }
                              label='Inserisci i dati di un nuovo contatto'
                            />
                            {contactMode == 'new' && (
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={4}>
                                  <MDInput
                                    fullWidth
                                    type='text'
                                    required
                                    label='nome'
                                    error={!!errors.user?.name}
                                    {...register('user.name', {
                                      required: true
                                    })}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                  <MDInput
                                    fullWidth
                                    type='text'
                                    required
                                    label='cognome'
                                    error={!!errors.user?.surname}
                                    {...register('user.surname', {
                                      required: true
                                    })}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                  <MDInput
                                    fullWidth
                                    type='email'
                                    label='email'
                                    required
                                    error={!!errors.user?.email}
                                    {...register('user.email', {
                                      pattern: {
                                        value:
                                          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'mail non valida'
                                      },
                                      required: true
                                    })}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                  <MDInput
                                    fullWidth
                                    type='text'
                                    required={false}
                                    label='telefono'
                                    // error={!!(errors('contact.phone'))}
                                    {...register('user.phone', {
                                      required: false
                                    })}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                  <MDInput
                                    fullWidth
                                    type='text'
                                    required={false}
                                    label='cellulare'
                                    // error={!!(errors('contact.mobile'))}
                                    {...register('user.mobile', {
                                      required: false
                                    })}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                  <MDTypography variant='subtitle2'>
                                    Settings
                                  </MDTypography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                  <MDInput
                                    fullWidth
                                    type='text'
                                    required={false}
                                    label='ruolo'
                                    error={!!errors.contact?.notes}
                                    {...register('notes', {
                                      required: false
                                    })}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                  <FormControl
                                    fullWidth
                                    required
                                    error={!!errors.notes}
                                  >
                                    <InputLabel>Permessi</InputLabel>
                                    <Select
                                      defaultValue=''
                                      label='Permessi'
                                      {...register('ticketPermissionId', {
                                        required: true
                                      })}
                                    >
                                      {enums &&
                                        enums.map(item => (
                                          <MenuItem
                                            key={item.id}
                                            value={item.id}
                                          >
                                            {item.description}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                  {isVisibleEmailMessage && (
                                    <MDBox display='flex' alignItems='center'>
                                      <Checkbox
                                        defaultChecked={
                                          obj?.receiveNotification
                                        }
                                        {...register('receiveNotification', {
                                          required: false
                                        })}
                                      />
                                      <MDBox ml={1}>
                                        <MDTypography
                                          variant='caption'
                                          fontWeight='medium'
                                          color='text'
                                        >
                                          Notifiche tramite email
                                        </MDTypography>
                                      </MDBox>
                                    </MDBox>
                                  )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                  <Controller
                                    name='tags'
                                    control={control}
                                    render={({
                                      field: { value, onChange }
                                    }) => (
                                      <Autocomplete
                                        multiple
                                        value={objTags || []}
                                        options={tags.map(option => option)}
                                        getOptionLabel={item =>
                                          item.name ? item.name : ''}
                                        onChange={(_, item) => {
                                          const _temp = mergeLists(tags, item)
                                          setTags(_temp)
                                          setObjTags(item)
                                          onChange(item || [])
                                        }}
                                        onClose={handleClose}
                                        renderInput={params => (
                                          <MDInput
                                            {...params}
                                            fullWidth
                                            label='tag'
                                            onChange={e =>
                                              updateTagList(e.target.value)}
                                            error={!!errors.user}
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                </Grid>
                                {isVisibleEmailMessage && (
                                  <Grid item xs={12} sm={12} md={12}>
                                    <MDTypography variant='subtitle2'>
                                      N.B.: verrà inviata una mail all'indirizzo
                                      indicato per la registrazione della
                                      password
                                    </MDTypography>
                                  </Grid>
                                )}
                                {/* <Grid item xs={12} sm={12} md={12}>
                                      <MDInput
                                        fullWidth
                                        type="text"
                                        multiline
                                        rows={3}
                                        required={false}
                                        label="note"
                                        // error={!!(errors('contact.notes'))}
                                        {...register("contact.notes", {
                                          required: false,
                                        })}
                                      />
                                    </Grid> */}
                              </Grid>
                            )}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                  {idObj !== 'new' && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={4}>
                        <MDInput
                          fullWidth
                          type='text'
                          disabled
                          label='nome'
                          {...register('user.name')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <MDInput
                          fullWidth
                          type='text'
                          disabled
                          label='cognome'
                          {...register('user.surname')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <MDInput
                          fullWidth
                          type='email'
                          disabled
                          label='email'
                          {...register('user.email')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <MDInput
                          fullWidth
                          disabled
                          type='text'
                          label='telefono'
                          {...register('user.phone')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <MDInput
                          fullWidth
                          disabled
                          type='text'
                          label='cellulare'
                          {...register('user.mobile')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <MDButton
                          color='info'
                          size='small'
                          component={Link}
                          to={'/users/' + obj.userId}
                        >
                          modifica dati utente
                        </MDButton>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <MDTypography variant='subtitle2'>
                          Settings
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <MDInput
                          fullWidth
                          type='text'
                          required={false}
                          label='ruolo'
                          error={!!errors.contact?.notes}
                          {...register('notes', {
                            required: false
                          })}
                        />
                      </Grid>
                      {obj?.ticketPermissionId && (
                        <Grid item xs={12} sm={12} md={4}>
                          <FormControl
                            fullWidth
                            required
                            error={!!errors.ticketPermissionId}
                          >
                            <InputLabel>Permessi</InputLabel>
                            <Select
                              label='Permessi'
                              defaultValue={obj?.ticketPermissionId}
                              {...register('ticketPermissionId', {
                                required: true
                              })}
                            >
                              {enums &&
                                enums.map(item => (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.description}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={12} md={4}>
                        {isVisibleEmailMessage && (
                          <MDBox display='flex' alignItems='center'>
                            <Checkbox
                              defaultChecked={obj?.receiveNotification}
                              {...register('receiveNotification', {
                                required: false
                              })}
                            />
                            <MDBox ml={1}>
                              <MDTypography
                                variant='caption'
                                fontWeight='medium'
                                color='text'
                              >
                                Notifiche tramite email
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Controller
                          name='tags'
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <Autocomplete
                              multiple
                              value={objTags || []}
                              options={tags.map(option => option)}
                              getOptionLabel={item =>
                                item.name ? item.name : ''}
                              onChange={(_, item) => {
                                const _temp = mergeLists(tags, item)
                                setTags(_temp)
                                setObjTags(item)
                                onChange(item || [])
                              }}
                              onClose={handleClose}
                              renderInput={params => (
                                <MDInput
                                  {...params}
                                  fullWidth
                                  label='tag'
                                  onChange={e => updateTagList(e.target.value)}
                                  error={!!errors.user}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      {obj.user && !obj.user.active && (
                        <Grid item xs={12} sm={12} md={4}>
                          <MDButton
                            variant='gradient'
                            color='secondary'
                            onClick={() => {
                              sendPasswordMail()
                            }}
                            disabled={isLoadingSendMail}
                          >
                            Invia mail per impostare la password
                            {isLoadingSendMail && (
                              <CircularProgress
                                color='white'
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  marginLeft: '10px'
                                }}
                              />
                            )}
                          </MDButton>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient'
                    color='secondary'
                    component={Link}
                    to={`/registries/${idRegistry}/contacts`}
                  >
                    Indietro
                  </MDButton>
                  <MDButton
                    type='submit'
                    variant='gradient'
                    color='success'
                    disabled={isLoadingSubmit}
                    sx={{ ml: 1 }}
                  >
                    Salva
                    {isLoadingSubmit && (
                      <CircularProgress
                        color='white'
                        style={{
                          width: '20px',
                          height: '20px',
                          marginLeft: '10px'
                        }}
                      />
                    )}
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <RegistryNav id={idRegistry} />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
