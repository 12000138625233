/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React components
import MDAvatar from 'components/MDAvatar'

// @mui icons
import Icon from '@mui/material/Icon'

// Images
import profilePicture from 'assets/images/avatar.png'
// ZeroUno
import { config } from 'variables/Config.js'
import authService from 'services/auth.service'
import LoginPage from 'views/auth/login.js'
import MainDashboard from 'views/dashboard'
import UserProfile01 from 'views/users/UserProfile.js'
import UsersTable from 'views/users/UsersTable.js'
import UserForm from 'views/users/UserForm.js'
import EnumerationTable from 'views/enumerations/EnumerationTable.js'
import EnumerationForm from 'views/enumerations/EnumerationForm.js'
import AttachmentTable from 'views/crm/AttachmentTable.js'
import AttachmentForm from 'views/crm/AttachmentForm.js'
import AddressTable from 'views/crm/AddressTable.js'
import AddressForm from 'views/crm/AddressForm.js'
import ContactTable from 'views/crm/ContactTable.js'
import ContactForm from 'views/crm/ContactForm.js'
import DocumentTable from 'views/crm/DocumentTable.js'
import DocumentForm from 'views/crm/DocumentForm.js'
import EventTable from 'views/crm/EventTable.js'
import EventForm from 'views/crm/EventForm.js'
import InvoiceTable from 'views/crm/InvoiceTable.js'
import InvoiceForm from 'views/crm/InvoiceForm.js'
import RegistryTable from 'views/crm/RegistryTable.js'
import RegistryForm from 'views/crm/RegistryForm.js'
import ProjectTable from 'views/crm/ProjectTable.js'
import ProjectForm from 'views/crm/ProjectForm.js'
import OrderTable from 'views/crm/OrderTable.js'
import OrderForm from 'views/crm/OrderForm.js'
import Statistics from 'views/crm/Statistics.js'
import TodoForm from 'views/crm/TodoForm.js'
import TodoTable from 'views/crm/TodoTable.js'
import ChangePasswordPage from 'views/auth/change-password'
import DeviceTable from 'views/crm/DeviceTable'
import DeviceForm from 'views/crm/DeviceForm'
import DeviceModelTable from 'views/crm/DeviceModelTable'
import DeviceModelForm from 'views/crm/DeviceModelForm'
import DeviceCustomerTable from 'views/crm/DeviceCustomerTable'
import DeviceCustomerForm from 'views/crm/DeviceCustomerForm'
import DdtTable from 'views/crm/DdtTable'
import DdtForm from 'views/crm/DdtForm'
import NewsletterForm from 'views/crm/NewsletterForm'
import NewsletterTable from 'views/crm/NewsletterTable'

const loggedUser = authService.user()

const routes = [
  {
    type: 'collapse',
    name: loggedUser ? loggedUser.name + ' ' + loggedUser.surname : '',
    key: 'loggedUser',
    icon: (
      <MDAvatar
        src={
          loggedUser && loggedUser.image
            ? config.SERVER_URL + '/download/' + loggedUser.image
            : profilePicture
        }
        alt='imgProfile'
        size='sm'
      />
    ),
    collapse: [
      {
        name: 'Il Mio Profilo',
        key: 'il-mio-profilo',
        component: <UserProfile01 />,
        route: '/myprofile',
        invisible: false
      },
      {
        name: 'Logout',
        key: 'logout',
        route: '/login',
        component: <LoginPage />,
        invisible: false
      },
      {
        name: 'Cambia password',
        key: 'change-password',
        route: '/change-password/:token',
        component: <ChangePasswordPage />,
        invisible: false
      }
    ]
  },
  { type: 'divider', key: 'divider-0' },
  {
    type: 'collapse',
    name: 'Dashboards',
    key: 'dashboards',
    icon: <Icon fontSize='medium'>dashboard</Icon>,
    collapse: [
      {
        type: 'collapse',
        name: 'Dashboard',
        key: 'dashboard',
        icon: <Icon fontSize='medium'>dashboard</Icon>,
        route: '/dashboards/main',
        component: <MainDashboard />
      }
    ]
  },
  {
    type: 'collapse',
    name: 'Area Amministrativa',
    key: 'amministrazione',
    icon: <Icon fontSize='medium'>supervisor_account</Icon>,
    collapse: [
      {
        name: 'Anagrafica',
        key: 'registry',
        route: '/registries/:idObj',
        component: <RegistryForm />,
        invisible: true
      },
      {
        name: 'Clienti/Fornitori',
        key: 'anagrafiche',
        route: '/registries',
        component: <RegistryTable />,
        invisible: !authService.isAdmin()
      },
      {
        name: 'Indirizzo',
        key: 'address',
        route: '/registries/:idRegistry/addresses/:idObj',
        component: <AddressForm />,
        invisible: true
      },
      {
        name: 'Indirizzi',
        key: 'addresses',
        route: '/registries/:idRegistry/addresses',
        component: <AddressTable />,
        invisible: true
      },
      {
        name: 'Contatto',
        key: 'contact',
        route: '/contacts/:idObj',
        component: <ContactForm />,
        invisible: true
      },
      {
        name: 'Contatto',
        key: 'contactRegistry',
        route: '/registries/:idRegistry/contacts/:idObj',
        component: <ContactForm />,
        invisible: true
      },
      {
        name: 'Contatti',
        key: 'contactsRegistry',
        route: '/registries/:idRegistry/contacts',
        component: <ContactTable />,
        invisible: true
      },
      {
        name: 'Documento',
        key: 'documentRegistry',
        route: '/registries/:idRegistry/documents/:idObj',
        component: <DocumentForm />,
        invisible: true
      },
      {
        name: 'Documenti',
        key: 'documentsRegistry',
        route: '/registries/:idRegistry/documents',
        component: <DocumentTable />,
        invisible: true
      },
      {
        name: 'Servizio',
        key: 'project',
        route: '/registries/:idRegistry/projects/:idObj',
        component: <ProjectForm />,
        invisible: true
      },
      {
        name: 'Servizi',
        key: 'projectsRegisty',
        route: '/registries/:idRegistry/projects',
        component: <ProjectTable />,
        invisible: true
      },
      {
        name: 'Allegato',
        key: 'attachment',
        route: '/projects/:idProject/attachments/:idObj',
        component: <AttachmentForm />,
        invisible: true
      },
      {
        name: 'Allegati',
        key: 'attachments',
        route: '/projects/:idProject/attachments',
        component: <AttachmentTable />,
        invisible: true
      },
      {
        name: 'Appuntamento',
        key: 'eventRegistry',
        route: '/registries/:idRegistry/events/:idObj',
        component: <EventForm />,
        invisible: true
      },
      {
        name: 'Appuntamenti',
        key: 'eventsRegistry',
        route: '/registries/:idRegistry/events',
        component: <EventTable />,
        invisible: true
      },
      {
        name: 'Evento',
        key: 'event',
        route: '/events/:idObj',
        component: <EventForm />,
        invisible: true
      },
      {
        name: 'Ordini Cliente',
        key: 'registryOrders',
        route: '/registries/:idRegistry/orders',
        component: <OrderTable />,
        invisible: true
      },
      {
        name: 'Ordine',
        key: 'order',
        route: '/registries/:idRegistry/orders/:idObj',
        component: <OrderForm />,
        invisible: true
      },
      {
        name: 'Ordini',
        key: 'orders',
        route: '/orders',
        component: <OrderTable />,
        invisible: !authService.isAdmin()
      },
      {
        name: 'Fatture Cliente',
        key: 'registryInvoices',
        route: '/registries/:idRegistry/invoices',
        component: <InvoiceTable />,
        invisible: true
      },
      {
        name: 'Fattura',
        key: 'invoice',
        route: '/registries/:idRegistry/invoices/:idObj',
        component: <InvoiceForm />,
        invisible: true
      },
      {
        name: 'Fatture',
        key: 'invoices',
        route: '/invoices',
        component: <InvoiceTable />,
        invisible: !authService.isAdmin()
      },
      {
        name: 'To Do',
        key: 'todo',
        route: '/todos/:idObj',
        component: <TodoForm />,
        invisible: true
      },
      {
        name: 'To Do',
        key: 'todos',
        route: '/todos',
        component: <TodoTable />,
        invisible: authService.isUser()
      },
      {
        name: 'Statistiche',
        key: 'statistics',
        route: '/stats',
        component: <Statistics />,
        invisible: true
      },
      {
        name: 'Prodotti / Servizi',
        key: 'prodotti',
        route: '/products',
        component: <DeviceTable />,
        invisible: !authService.isAdmin()
      },
      {
        name: 'Prodotto',
        key: 'prodotto',
        route: '/products/:idObj',
        component: <DeviceForm />,
        invisible: true
      },
      {
        name: 'Lista Ddt',
        key: 'ddts',
        route: '/ddts',
        component: <DdtTable />,
        invisible: !authService.isAdmin()
      },
      {
        name: 'Ddt',
        key: 'ddt',
        route: '/ddts/:idObj',
        component: <DdtForm />,
        invisible: true
      },
      {
        name: 'Clienti apparati',
        key: 'client-apparati',
        route: '/registries/:idRegistry/device-customers',
        component: <DeviceCustomerTable />,
        invisible: true
      },
      {
        name: 'Clienti apparati',
        key: 'cliente-apparato',
        route: '/registries/:idRegistry/device-customers/:idObj',
        component: <DeviceCustomerForm />,
        invisible: true
      },
      {
        name: 'Newsletter e sms ',
        key: 'newsletters',
        route: '/newsletters',
        component: <NewsletterTable />,
        invisible: !authService.isAdmin()
      },
      {
        name: 'Newsletter',
        key: 'newsletter',
        route: '/newsletters/:idObj',
        component: <NewsletterForm />,
        invisible: true
      },
      {
        type: 'collapse',
        name: 'Video Conferenza',
        key: 'videoconferenza',
        href: 'https://meeting.vianova.it/5871690242',
        noCollapse: true,
        invisible: false
      }
    ]
  },
  {
    type: 'collapse',
    name: 'Impostazioni',
    key: 'impostazioni',
    icon: <Icon fontSize='medium'>settings</Icon>,
    invisible: authService.isUser(),
    collapse: [
      {
        name: 'Utente',
        key: 'user',
        route: '/users/:idUser',
        component: <UserForm />,
        invisible: true
      },
      {
        name: 'Utenti',
        key: 'users',
        route: '/users',
        component: <UsersTable />,
        invisible: !authService.isAdmin()
      },
      {
        name: 'Tipologia Prodotti',
        key: 'tipo-prodotti',
        route: '/models',
        component: <DeviceModelTable />,
        invisible: !authService.isAdmin()
      },
      {
        name: 'Tipologia Prodotto',
        key: 'tipo-prodotto',
        route: '/models/:idObj',
        component: <DeviceModelForm />,
        invisible: true
      },
      {
        name: 'Descrizioni',
        key: 'enumerations',
        route: '/enumerations',
        component: <EnumerationTable />,
        invisible: !authService.isAdmin()
      },
      {
        name: 'Descrizione',
        key: 'enumeration',
        route: '/enumerations/:idObj',
        component: <EnumerationForm />,
        invisible: true
      }
    ]
  },
  { type: 'divider', key: 'divider-1' },
  {
    type: 'collapse',
    name: '01Rabbit',
    key: '01Rabbit',
    href: 'https://www.01rabbit.it',
    icon: <Icon fontSize='medium'>architecture</Icon>,
    noCollapse: true,
    invisible: false
  }
]

export default routes
