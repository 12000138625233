import { forwardRef } from 'react'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'
import Autocomplete from '@mui/material/Autocomplete'
import MDInput from 'components/MDInput'
import { Controller } from 'react-hook-form'

const ZUAutoComplete = forwardRef(
  (
    {
      name,
      label,
      control,
      options,
      defaultValue,
      isOptionEqualToValue,
      getOptionLabel,
      required,
      rules,
      error,
      disabled
    },
    ref
  ) => (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Autocomplete
          {...field}
          options={options}
          onChange={(_, data) => field.onChange(data)}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          disabled={disabled}
          // disableClearable
          renderInput={params => (
            <MDInput
              {...params}
              fullWidth
              required={required}
              label={label}
              error={error}
            />
          )}
        />
      )}
    />
  )
)

const defIsOptionEqualToValue = (option, value) => option.id === value.id

// Setting default values for the props
ZUAutoComplete.defaultProps = {
  required: false,
  error: false,
  disabled: false,
  defaultValue: null,
  isOptionEqualToValue: defIsOptionEqualToValue
}

// Typechecking props for the ZUTitleForm
ZUAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.any,
  rules: PropTypes.object.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  isOptionEqualToValue: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool
}

export default ZUAutoComplete
