/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react'

// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Card from '@mui/material/Card'
import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Grid'
import MuiLink from '@mui/material/Link'

// @mui icons
import FacebookIcon from '@mui/icons-material/Facebook'
import GitHubIcon from '@mui/icons-material/GitHub'
import GoogleIcon from '@mui/icons-material/Google'
import InputAdornment from '@mui/material/InputAdornment'
import AccountCircle from '@mui/icons-material/AccountCircle'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import MDSnackbar from 'components/MDSnackbar'

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout'

// Images
// import bgImage from 'assets/images/bg-sign-in-basic.jpeg'
import bgImage from 'assets/images/zerounorabbit/bg04.jpg'
import logo from 'assets/images/zerounorabbit/logo.png'

// ZeroUno
import { config } from 'variables/Config.js'
import authService from 'services/auth.service'
import { useForm } from 'react-hook-form'

function LoginPage() {
  const [rememberMe, setRememberMe] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()
  const [errorMsg, setErrorMsg] = useState('')
  const [tr, setTR] = useState(false)

  useEffect(() => {
    authService.logout()
  })

  const handleSetRememberMe = () => setRememberMe(!rememberMe)

  const login = (formData) => {
    authService.login(formData.email, formData.password).then(
      (data) => {
        window.location.href = '/'
      },
      (error) => {
        if (error.response) {
          console.error(error.response.data.message)
          showNotification(error.response.data.message)
        } else {
          showNotification('errore login')
        }
      }
    )
  }

  const showNotification = (message) => {
    if (!tr) {
      setTR(true)
      setTimeout(function () {
        setTR(false)
      }, 6000)
      setErrorMsg(message)
    }
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox pt={1} pb={3} px={3}>
          <MDBox
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 1,
              m: 1
            }}
          >
            <MDBox
              component='img'
              src={logo}
              alt='logo'
              width='80%'
              height='80%'
              position='relative'
              mb={1}
              p={3}
            />

          </MDBox>
          {/* <MDTypography variant='h4' fontWeight='light' color='info'>
            {config.APP_NAME}
          </MDTypography> */}
          <form onSubmit={handleSubmit(login)}>
            <MDBox mb={2}>
              <MDInput
                type='email'
                label='email'
                fullWidth
                autoFocus
                error={!!errors.email}
                required
                autoComplete='email'
                {...register('email', { required: true })}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type='password'
                label='Password'
                fullWidth
                error={!!errors.password}
                required
                autoComplete='current-password'
                {...register('password', { required: true })}
              />
            </MDBox>
            {config.SAMPLE_DATA && (
              <MDBox display='flex' alignItems='center' ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant='button'
                  fontWeight='regular'
                  color='text'
                  onClick={handleSetRememberMe}
                  sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MDTypography>
              </MDBox>
            )}
            <MDBox mt={4} mb={1}>
              <MDButton variant='gradient' color='info' fullWidth type='submit'>
                accedi
              </MDButton>
            </MDBox>
            {config.SAMPLE_DATA && (
              <MDBox mt={3} mb={1} textAlign='center'>
                <MDTypography variant='button' color='text'>
                  Don&apos;t have an account?{' '}
                  <MDTypography
                    component={MuiLink}
                    to='/authentication/sign-up/cover'
                    variant='button'
                    color='info'
                    fontWeight='medium'
                    textGradient
                  >
                    Registrati
                  </MDTypography>
                </MDTypography>
              </MDBox>
            )}
            <MDBox mt={1} mb={1} textAlign='center'>
              <MuiLink href='/#/reset-password'>
                <MDTypography variant='button' fontWeight='medium'>
                  password dimenticata?
                </MDTypography>
              </MuiLink>
            </MDBox>
          </form>
        </MDBox>
      </Card>
      <MDSnackbar
        color='error'
        icon='notifications'
        title='Errore'
        content={errorMsg}
        open={tr}
        close={() => setTR(false)}
      />
    </BasicLayout>
  )
}

export default LoginPage
