import MaterialReactTable from 'material-react-table'
import { darken } from '@mui/material'
import { useMemo } from 'react'
import MDBox from 'components/MDBox'
import { MRT_Localization_IT } from 'material-react-table/locales/it'

function ZuDataTable ({
  table,
  renderTopToolbarCustomActions,
  enableColumnOrdering
}) {
  const columns = useMemo(() => table.columns, [table])
  const data = useMemo(() => table.rows, [table])
  return (
    <MDBox px={3}>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnOrdering={enableColumnOrdering}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
        localization={MRT_Localization_IT}
        muiTablePaperProps={{
          elevation: 0,
          sx: theme => ({
            borderRadius: '0',
            border: '1px dashed #e0e0e0'
          })
        }}
        muiTopToolbarProps={{
          sx: theme => ({
            backgroundColor: theme.palette.white.main
          })
        }}
        muiTableHeadCellProps={{
          sx: theme => ({
            backgroundColor: theme.palette.white.main
          })
        }}
        muiTableBodyProps={{
          sx: theme => ({
            '& tr': {
              backgroundColor: theme.palette.white.main
            },
            '& tr:nth-of-type(odd)': {
              backgroundColor: darken(theme.palette.background.default, 0.1)
            }
          })
        }}
        muiTableFooterCellProps={{
          sx: theme => ({
            backgroundColor: theme.palette.white.main
          })
        }}
        muiBottomToolbarProps={{
          sx: theme => ({
            backgroundColor: theme.palette.white.main
          })
        }}
      />
    </MDBox>
  )
}

export default ZuDataTable
