'use strict'
import { useState, useEffect } from 'react'
// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Stack from '@mui/material/Stack'
// import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import ZuDataTable from 'components/ZeroUno/ZuDataTable'

// zerouno
import mainService from 'services/invoice.service'
import { useLocation, useParams, Link } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import RegistryNav from './RegistryNav'
import utils from 'utils/utils.js'
import { config } from 'variables/Config.js'
import { DateTime } from 'luxon'

export default function InvoiceTable() {
  const location = useLocation()

  const { idRegistry } = useParams()
  const [objId, setObjId] = useState(0)
  const [objects, setObjects] = useState([])
  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const [openConfirm, setOpenConfirm] = useState(false)

  useEffect(() => {
    fetchObjects()
  }, [location])

  const fetchObjects = () => {
    mainService.findAll(idRegistry).then(
      response => {
        setObjects(response.data)
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  // Delete
  const deleteObject = () => {
    setOpenConfirm(false)
    mainService.remove(objId).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        fetchObjects()
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const downloadXmlInvoice = (id, num, year) => {
    let fileName = 'IT'
    mainService
      .findById(id)
      .then(response => {
        const invoice = response.data
        const randomString = Math.random().toString(36).substring(2, 7)
        fileName = 'IT01879020517_' + randomString + '.xml' // Aruba
        fileName = 'IT01641790702_' + randomString + '.xml' // fatture in cloud
        return invoice
      })
      .then(invoice => {
        mainService.downloadXmlInvoice(id).then(response => {
          console.log('response: ', response)
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
        })
      })
  }

  const downloadXmlInvoices = () => {
    mainService.downloadXmlInvoices().then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const date = DateTime.now().toFormat('yyyyMMdd')
      link.setAttribute('download', `fatture-${date}.zip`)
      document.body.appendChild(link)
      link.click()
    })
  }

  const columns = [
    ...(!idRegistry
      ? [
        {
          header: 'cliente',
          accessorFn: d => d.registry.businessName.trim()
        }
      ]
      : []),
    {
      header: 'num',
      accessorFn: d => {
        return d.number + (d.sub ? '/' + d.sub : '')
      }
    },
    {
      header: 'data',
      accessorFn: d => {
        return utils.formatDate(d.dateInvoice, 'dd/MM/yyyy')
      }
    },
    {
      header: 'imponibile',
      accessorFn: d => {
        return utils.formatFloat(d.taxable, 2)
      }
    },
    {
      header: 'iva',
      accessorFn: d => {
        return utils.formatFloat(d.vat, 2)
      }
    },
    {
      header: 'totale',
      accessorFn: d => {
        return utils.formatFloat(d.totalAmount, 2)
      }
    },
    {
      header: 'azioni',
      enableSorting: false,
      enableColumnFilter: false,
      Cell: ({ row }) => (
        <Grid container spacing={1}>
          <Grid item>
            <MDButton
              color='primary'
              size='small'
              iconOnly
              onClick={() =>
                downloadXmlInvoice(
                  row.original.id,
                  row.original.number,
                  row.original.year
                )}
            >
              <Icon>download</Icon>
            </MDButton>
          </Grid>
          <Grid item>
            <MDButton
              color='info'
              size='small'
              iconOnly
              component={Link}
              to={`/registries/${row.original.registryId}/invoices/${row.original.id}`}
            >
              <Icon>edit</Icon>
            </MDButton>
          </Grid>
          <Grid item>
            <MDButton
              iconOnly
              color='error'
              size='small'
              onClick={() => {
                setObjId(row.original.id)
                setOpenConfirm(true)
              }}
            >
              <Icon>delete</Icon>
            </MDButton>
          </Grid>
        </Grid>
      )
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={idRegistry ? 10 : 12}>
            <Card>
              <ZUTitleForm bgColor='warning' title='Fatture' icon='settings' />
              {false && (
                <MDBox p={3} lineHeight={1}>
                  <MDButton
                    color='info'
                    m={1}
                    onClick={() => downloadXmlInvoices()}
                  >
                    download fatture elettroniche del mese di{' '}
                    {DateTime.now().setLocale('it').toFormat('MMMM yyyy')}
                  </MDButton>
                </MDBox>
              )}
              <MDBox p={3} lineHeight={1}>
                {!idRegistry && (
                  <MDButton
                    color='info'
                    href={`${config.SERVER_URL}/invoices/fatture.zip`}
                    target='_blank'
                    m={1}
                  >
                    download fatture elettroniche del mese di{' '}
                    {DateTime.now().setLocale('it').toFormat('MMMM yyyy')}
                  </MDButton>
                )}
              </MDBox>
              <ZuDataTable table={{ columns: columns, rows: objects }} />
            </Card>
          </Grid>
          {idRegistry && (
            <Grid item xs={12} sm={12} md={2}>
              <RegistryNav id={idRegistry} />
            </Grid>
          )}
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>
            Conferma
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
