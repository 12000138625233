'use strict'

import React, { useEffect } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import CircularProgress from '@mui/material/CircularProgress'
// import Icon from '@mui/material/Icon'
// import InputLabel from '@mui/material/InputLabel'
// import MenuItem from '@mui/material/MenuItem'
// import FormControl from '@mui/material/FormControl'
// import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'

// zerouno
import documentService from 'services/document.service'
import { config } from 'variables/Config.js'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import RegistryNav from './RegistryNav'

export default function DocumentForm() {
  const { idObj } = useParams()
  const { idRegistry } = useParams()
  const [obj, setObj] = React.useState({})
  const [showSnackbar, setShowSnackbar] = React.useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm()
  const [file, setFile] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  // const { data: obj, isLoading, error } = documentService.get(idRegistry, idObj)

  const navigate = useNavigate()

  useEffect(() => {
    fetchObject()
  }, [])

  useEffect(() => {
    reset({ ...obj })
  }, [obj])

  const fetchObject = () => {
    if (idObj === 'new') {
      setObj({})
    } else {
      documentService.findById(idRegistry, idObj).then(
        response => {
          setObj(response.data)
          setFile(response.data.file)
        },
        error => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        }
      )
    }
  }

  const onSubmit = data => {
    data.file = file
    if (data.file === '') {
      setShowSnackbar({ sh: true, msg: 'inserire il file', color: 'error' })
      return
    }
    if (idObj === 'new') {
      addObj(idRegistry, data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = (idRegistry, data) => {
    documentService.create(idRegistry, data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate(`/registries/${idRegistry}/documents`)
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const updateObj = (data, id) => {
    documentService.update(idRegistry, id, data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate(`/registries/${idRegistry}/documents`)
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const uploadFile = files => {
    const newFile = files[0]
    if (newFile) {
      if (newFile.size <= 50 * 1024000) {
        setLoading(true)
        const formData = new FormData()
        formData.append('file', newFile)
        documentService.upload(formData, idRegistry).then(
          response => {
            setLoading(false)
            const filename = response.data
            setFile(filename)
          },
          error => {
            setLoading(false)
            setShowSnackbar({
              sh: true,
              msg: 'errore upload file',
              color: 'error'
            })
          }
        )
      } else {
        setShowSnackbar({ sh: true, msg: 'file troppo grande', color: 'error' })
      }
    }
  }

  const removeFile = () => {
    const formData = new FormData()
    formData.delete('file')
    setFile('')
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={10}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm
                  bgColor='warning'
                  title='Documento'
                  icon='settings'
                />

                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={9}>
                      <MDInput
                        fullWidth
                        type='text'
                        autoFocus
                        required
                        label='nome'
                        error={!!errors.name}
                        {...register('name', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <Stack spacing={2}>
                        {(file === null ||
                          file === undefined ||
                          file === '') && (
                            <label htmlFor='contained-button-file'>
                              <MDInput
                                accept='*'
                                id='contained-button-file'
                                type='file'
                                sx={{ display: 'none' }}
                                onChange={target =>
                                  uploadFile(target.target.files)}
                              />
                              <MDButton
                                variant='gradient'
                                color='info'
                                component='span'
                                style={{ marginTop: '30px' }}
                              >
                                Upload documento (max 50Mb)
                              </MDButton>
                            </label>
                          )}
                        {loading && (
                          <CircularProgress color='primary' sx={{ m: 2 }} />
                        )}
                        {file && (
                          <MDButton
                            color='primary'
                            href={`${config.SERVER_URL}/registries/${idRegistry}/documents/download/${file}`}
                            target='_blank'
                            mb={2}
                          >
                            download
                          </MDButton>
                        )}
                        {file && (
                          <MDButton color='error' onClick={() => removeFile()}>
                            elimina file
                          </MDButton>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient'
                    color='secondary'
                    component={Link}
                    to={`/registries/${idRegistry}/documents`}
                  >
                    Indietro
                  </MDButton>
                  <MDButton
                    type='submit'
                    variant='gradient'
                    color='success'
                    sx={{ ml: 1 }}
                  >
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <RegistryNav id={idRegistry} />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
