'use strict'

import React, { useEffect } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
// import Select from '@mui/material/Select'
// import Icon from '@mui/material/Icon'
// import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
// import FormControl from '@mui/material/FormControl'
// import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'

// zerouno
import newsletterService from 'services/newsletter.service'
// import uploadService from 'services/upload.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import ZUSelect from 'components/ZeroUno/ZUSelect'
import ZUAutoComplete from 'components/ZeroUno/ZUAutoComplete'
import RegistryNav from './RegistryNav'
import { DateTime } from 'luxon'
import utils from 'utils/utils.js'
import MDEditor from 'components/MDEditor'
import { Autocomplete, CircularProgress } from '@mui/material'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'

export default function NewsletterForm() {
  const [showSnackbar, setShowSnackbar] = React.useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    setError
  } = useForm()
  const { idObj } = useParams()
  const [obj, setObj] = React.useState({})
  const [addresses, setAddresses] = React.useState([])
  const [selectedAddresses, setSelectedAddresses] = React.useState([])
  const [isLoadingSubmit, setIsLoadingSubmit] = React.useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    fetchObject()
  }, [])

  useEffect(() => {
    reset({ ...obj })
  }, [obj])

  const fetchObject = () => {
    console.log('DateTime', DateTime.local().startOf('hour').plus(1, 'hour').toFormat('HH:mm'))
    let _obj = {}
    if (idObj === 'new') {
      _obj = {
        addresses: '',
        subject: '',
        scheduledOn: DateTime.local().toISODate(),
        scheduledOnTime: DateTime.local().startOf('hour').plus(1, 'hour').toFormat('HH:mm'),
        content: ''
      }
      fetchAddresses(_obj)
      setObj(_obj)
    } else {
      newsletterService.get(idObj).then(
        (response) => {
          _obj = response.data
          console.log('_obj: ', _obj)
          fetchAddresses(_obj)
          setObj(_obj)
        },
        (error) => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        }
      )
    }
  }

  const fetchAddresses = (objNewsletter) => {
    let _addresses = []
    newsletterService.getAddresses().then(
      (response) => {
        _addresses = response.data
        console.log('_addresses: ', _addresses)
        if (objNewsletter.addresses && _addresses.length > 0) {
          setSelectedAddresses(
            _addresses?.filter((x) =>
              objNewsletter.addresses.split(';').includes(x.key)
            )
          )
        }
        console.log('_addresses: ', _addresses)
        setAddresses(_addresses)
      },
      error =>
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
    )
  }

  const onChange = text => {
    setObj({ ...obj, content: text })
  }

  const onSubmit = (data) => {
    if (!utils.isValidTime(data.scheduledOnTime)) {
      setShowSnackbar({
        sh: true,
        msg: 'ora non valida',
        color: 'error'
      })
      setError('scheduledOnTime', { message: 'ora non valida' })
      return
    }
    data.addresses = selectedAddresses.map((x) => x.key).join(';')
    if (idObj === 'new') {
      addObj(data)
    } else {
      data.id = idObj
      updateObj(data, idObj)
    }
  }

  // Add obj
  const addObj = (data) => {
    newsletterService.create(data).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate('/newsletters')
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const updateObj = (data, id) => {
    // data.image = obj.image
    newsletterService.update(id, data).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate('/newsletters')
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm bgColor='warning' title='Newsletter / Mail / SMS' icon='settings' />

                <MDBox p={2}>
                  <Grid container spacing={2}>

                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='oggetto / testo sms'
                        error={!!errors.subject}
                        {...register('subject', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDEditor
                        value={obj?.content || ''}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Controller
                        name='address'
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Autocomplete
                            multiple
                            options={addresses}
                            value={selectedAddresses || []}
                            onChange={(_, item) => {
                              setSelectedAddresses([...item])
                            }}
                            getOptionLabel={(item) =>
                              item.value ? item.value : ''}
                            renderInput={(params) => (
                              <MDInput
                                {...params}
                                fullWidth
                                label='Seleziona gli indirizzi'
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='date'
                        required
                        label='data'
                        error={!!errors.scheduledOn}
                        {...register('scheduledOn', { required: true })}
                      />

                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='ora (hh:mm)'
                        error={!!errors.scheduledOnTime}
                        {...register('scheduledOnTime', { required: true })}
                      />

                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        control={control}
                        name='invia sms'
                        render={({ field: { value, onChange } }) => (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={value || false}
                                onChange={onChange}
                              />
                            }
                            label='invia sms'
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient'
                    color='secondary'
                    component={Link}
                    to='/newsletters'
                  >
                    Indietro
                  </MDButton>
                  <MDButton
                    type='submit'
                    variant='gradient'
                    color='success'
                    disabled={isLoadingSubmit}
                    sx={{ ml: 1 }}
                  >
                    Salva
                    {isLoadingSubmit && (
                      <CircularProgress
                        color='white'
                        style={{
                          width: '20px',
                          height: '20px',
                          marginLeft: '10px'
                        }}
                      />
                    )}
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
