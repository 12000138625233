import React, { useEffect, useContext } from 'react'
// @material-ui/core components
// import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'

// import Icon from '@mui/material/Icon'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

// zerouno
import { Link } from 'react-router-dom'
import registryService from 'services/registry.service'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

export default function RegistryNav(props) {
  const [obj, setObj] = React.useState({
    businessName: ''
  })

  useEffect(() => {
    fetchObject()
  }, [])

  const fetchObject = () => {
    if (props.id && props.id !== 'new') {
      registryService.findById(props.id).then(
        response => {
          setObj(response.data)
        },
        error => {
          console.log(error)
        }
      )
    }
  }

  return (
    <Card>
      <MDBox p={2}>
        <MDTypography variant='h6' sx={{ mb: 2 }}>
          {obj.businessName}
        </MDTypography>
        {props.id !== 'new' && (
          <Stack spacing={2}>
            <MDButton
              color='info'
              component={Link}
              to={'/registries/' + props.id}
            >
              anagrafica
            </MDButton>
            <MDButton
              color='info'
              component={Link}
              to={'/registries/' + props.id + '/device-customers'}
            >
              Prodotti / Servizi
            </MDButton>
            <MDButton
              color='info'
              component={Link}
              to={'/registries/' + props.id + '/contacts'}
            >
              contatti
            </MDButton>
            <MDButton
              color='info'
              component={Link}
              to={'/registries/' + props.id + '/addresses'}
            >
              indirizzi
            </MDButton>
            <MDButton
              color='info'
              component={Link}
              to={'/registries/' + props.id + '/projects'}
            >
              servizi
            </MDButton>
            <MDButton
              color='info'
              component={Link}
              to={'/registries/' + props.id + '/events'}
            >
              Appuntamenti
            </MDButton>
            <MDButton
              color='info'
              component={Link}
              to={'/registries/' + props.id + '/documents'}
            >
              Documenti
            </MDButton>
            <MDButton
              color='info'
              component={Link}
              to={'/registries/' + props.id + '/orders'}
            >
              Ordini
            </MDButton>
            <MDButton
              color='info'
              component={Link}
              to={'/registries/' + props.id + '/invoices'}
            >
              Fatture
            </MDButton>
          </Stack>
        )}
      </MDBox>
    </Card>
  )
}

RegistryNav.propTypes = {
  id: PropTypes.string.isRequired
}
