const prod = {
  SERVER_URL: 'https://operatori.utenze.online/api',
  SAMPLE_DATA: false,
  APP_NAME: 'UtenzeOnline'
}

const dev = {
  SERVER_URL: 'http://localhost:8080/api',
  SAMPLE_DATA: false,
  APP_NAME: 'UtenzeOnline dev.'

}

export const config = process.env.NODE_ENV === 'production' ? prod : dev
